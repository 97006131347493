export const ALERT = "ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const UpdateAlert = (alert) => {
  return {
    type: ALERT,
    alert: alert,
  };
};

export const ClearAlert = () => {
  return {
    type: CLEAR_ALERT,
  };
};
