import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import RTL from "components/RTL";
import useSettings from "hooks/useSettings";
import { PersistGate } from "redux-persist/integration/react";
import { useRoutes } from "react-router-dom";
import routes from "routes";
import { createCustomTheme } from "theme";
import { store, persistor } from "./store/index.js";
import { Provider } from "react-redux";
import "./i18n";

import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoaderIcon } from "react-hot-toast";
import LoadingScreen from "components/LoadingScreen.jsx";

const App = () => {
  const content = useRoutes(routes());
  const { t, ready } = useTranslation()

  const { settings } = useSettings();
  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
  });
  if (!ready) {
    return <LoadingScreen />
  }
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <RTL>
                <CssBaseline />

                {content}
              </RTL>
            </ThemeProvider>
          </StyledEngineProvider>
        }
      </PersistGate>
    </Provider>
  );
};

export default App;
