import React, { Fragment, useEffect, useState } from "react";
import { Box, Card, useTheme, styled, Tab, Button } from "@mui/material";
import FileItem from "./FileItem";
import FilesSearch from "./FilesSearch";
import { DeteleFiles, GetFiles, GetUploadTypes } from "contexts/API";
import FileModal from "./FileModal";
import { H3 } from "components/Typography";
import { t } from "i18next";
import { MEDIA_URL } from "contexts/JWTAuth";

export default function FilesList() {
  const [fileList, setFilelist] = useState(null);
  const [uploadtypes, setUploadtypes] = useState(null);
  const [showModal, setShowModal] = useState({ open: false, edit: false });
  const [updateFilevalues, setUpdateFileValues] = useState(null);

  const fetchFilesAndTypes = async () => {
    const files = await GetFiles();
    const uploadTypes = await GetUploadTypes();
    files &&
      setFilelist(
        files.map((item) => {
          const url = uploadTypes.find(
            (i) => i.FileType === item.fileType
          )?.FileTypeImageUrl;
          return {
            ...item,
            FileTypeImageUrl: url
              ? `${MEDIA_URL}${url}`
              : null,
          };
        })
      );
    uploadTypes && setUploadtypes(uploadTypes);
  };

  useEffect(async () => {
    fetchFilesAndTypes();
  }, []);

  const theme = useTheme();

  const handleCloseModal = async () => {
    setShowModal({ open: false, edit: false });
    setUpdateFileValues(null);
    fetchFilesAndTypes();
  };

  const handleDeleteFile = (file) =>
    DeteleFiles({ fileId: file.fileId }).then((res) => {
      res.code === 1 &&
        setFilelist((pre) => pre.filter((item) => item.fileId !== file.fileId));
    });

  const handleEditFile = (file) => {
    setUpdateFileValues({
      fileId: file.fileId,
      fileDescription: file.fileDescription,
    });

    setShowModal({ open: true, edit: true });
  };

  return (
    <Fragment>
      <Box mt={4}>
        <H3 mb={3}>{t("Files")}</H3>
        <Card
          sx={{
            position: "relative",
          }}
        >
          <FileModal
            closeModal={handleCloseModal}
            edit={showModal.edit}
            open={showModal.open}
            handleClose={() => setShowModal({ open: false, edit: false })}
            uploadtypes={uploadtypes}
            updateFilevalues={updateFilevalues}
          />
          <FilesSearch
            onSerachType={null}
            addNewFile={() => {
              setShowModal({ open: true, edit: false });
            }}
          />
          <FileItem
            listType={"all"}
            listItems={fileList}
            handleCheck={null}
            onCheckItem={null}
            onDeleteFile={handleDeleteFile}
            onEditFiles={handleEditFile}
          />
        </Card>
      </Box>
    </Fragment>
  );
}
