import React from "react";
import { BASE_URL } from "./JWTAuth";
import axios from "../utils/axios";
import { useSelector } from "react-redux";
import { store } from "../store";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Logout } from "../store/actions/auth";
import { Navigate } from "react-router-dom";

const sessionId =
  store.getState().persistedReducer?.authReducer?.user?.sessionId;
const userId = store.getState().persistedReducer?.authReducer?.user?.userId;
const defaultCustomer =
  store.getState().persistedReducer?.authReducer?.user?.defaultCustomer_ID;

axios.interceptors.request.use(async (request) => {
  const token = store?.getState()?.persistedReducer?.authReducer?.user?.token;
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.code === "ECONNREFUSED") {
      console.log(err);
      // Handle the case where the backend server is not running
      console.log("Backend server is not running");
      // You can choose to display an error message to the user or retry the request after some time
    } else if (err.response) {
      if (err.response.status === 401) {
        try {
          store.dispatch(Logout());
          Navigate("/login");
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (err.response.status === 500) {
        // Handle the case where there is an internal server error
        console.log("Internal server error");
        // You can choose to display an error message to the user or retry the request after some time
      }
    }
  }
);

export const CreateCustomer = async (props) => {
  try {
    const data = await axios.put(
      `${BASE_URL}/Customer/Save`,
      {
        customer_ID: props.customer_ID,
        isCompany: props.isCompany, //true,
        firstName: props.firstName, //"string",
        lastName: props.lastName, //"string",
        street: props.street, //"string",
        postalCode_ID: props.postalCode_ID, //ID,
        postalCodeName: props.postalCodeName, //"string",
        country_ID: props.country_ID, //ID",
        phone1: props.phone1, //"string",
        eMail: props.eMail, //"string",
        wwwPage: props.wwwPage, //"string",
        isTaxPayer: props.isTaxPayer, //boolean,
        taxNumber: props.taxNumber, //"string",
        iban: props.iban, //"string",
        swift: props.swift, //"string",
        eCommType: props.eCommType, //number,
        eCommAddress: props.eCommAddress, //"string"
      },
      {
        params: { sessionId: sessionId ? sessionId : null },
      }
    );
    return data;
  } catch (error) {
    // this failed, so let's redirect to the login page
    console.log(error);
  }
};

export const GetUserCustomers = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/User/GetCustomers`, {
      params: {
        sessionId,
      },
    });
    return data;
  } catch (error) {
    // this failed, so let's redirect to the login page
    console.log(error);
  }
};

export const ForgotUserPassword = async (Username) => {
  try {
    const data = await axios.get(`${BASE_URL}/User/ForgotPassword`, {
      params: {
        sessionId,
        Username,
      },
    });
    return data;
  } catch (error) {
    // this failed, so let's redirect to the login page
    console.log(error);
  }
};

export const ResetUserPassword = async (Username, Code, NewPassword) => {
  try {
    const data = await axios.get(`${BASE_URL}/User/ResetPassword`, {
      params: {
        sessionId,
        Username,
        Code,
        NewPassword,
      },
    });
    return data;
  } catch (error) {
    // this failed, so let's redirect to the login page
    console.log(error);
  }
};

export const CreateContactAccount = async (Contact_ID) => {
  const { data } = await axios.get(`${BASE_URL}/Contact/CreateAccount`, {
    params: {
      sessionId,
      Contact_ID,
    },
  });
  return data;
};
//?ListName=ContactTitle&filter=
export const GetCountryList = async (listName, filter) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/Common/GetList`, {
      params: {
        sessionId,
        listName,
        filter,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const GetContactTitle = async (listName, filter) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/Common/GetList`, {
      params: {
        sessionId,
        listName,
        filter,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const GetListForShow = async (
  Show_ID,
  listName,
  sampleTypeKey,
  filter
) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/Common/GetListForShow`, {
      params: {
        Show_ID: Show_ID ? Show_ID : "",
        listName: listName ? listName : "",
        sampleTypeKey: sampleTypeKey ? sampleTypeKey : "",
        filter: filter ? filter : "",
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const GetPostalCodesList = async (Country_Id, Filter) => {
  const { data } = await axios.get(`${BASE_URL}/Common/GetPostalCodes`, {
    params: {
      sessionId,
      Filter,
      Country_Id,
    },
  });
  return data;
};

export const CustomerSearch = async (country_Id, searchString) => {
  try {
    const data = await axios.get(`${BASE_URL}/Customer/Search`, {
      params: {
        sessionId,
        searchString,
        country_Id,
      },
    });
    return data;
  } catch (error) {
    // this failed, so let's redirect to the login page
    console.log(error);
    return [];
  }
};

export const GetECommType = async () => {
  const { data } = await axios.get(`${BASE_URL}/Customer/GetECommType`, {
    params: {
      sessionId,
    },
  });
  return data;
};

export const GetCustomer = async (customer_Id, TaxNumber) => {
  const { data } = await axios.get(`${BASE_URL}/Customer/Get`, {
    params: {
      sessionId,
      customer_Id,
      TaxNumber,
    },
  });
  return data;
};

export const UpdateUser = async (props) => {
  try {
    const data = await axios.put(
      `${BASE_URL}/User/Update`,
      {
        userId: props.userId || userId,
        userName: props?.username,
        firstName: props?.firstName,
        lastName: props?.lastName,
        email: props?.email,
        password: props?.password,
        mobileNumber: props?.phone,
        defaultLanguage: props.defaultLanguage,
        emailConfirmed: props?.emailConfirmed,
        mobileNumberConfirmed: props?.mobileNumberConfirmed,
        defaultCustomer_ID: props?.defaultCustomer_ID,
        isAdministrator: props?.isAdministrator,
      },
      {
        params: { sessionId: sessionId ? sessionId : null },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const CustomerRequestJoin = async (Customer_ID) => {
  console.log(userId);
  const { data } = await axios.put(
    `${BASE_URL}/Customer/JoinRequest`,
    {},
    {
      params: {
        sessionId: sessionId ? sessionId : null,
        Customer_ID: Customer_ID,
      },
    }
  );
  return data;
};

export const ApplicationSave = async (showData) => {
  const data = await axios.put(`${BASE_URL}/Application/Save`, showData, {
    params: { sessionId: sessionId ? sessionId : null },
  });
  return data;
};

export const ApplicationSetStatus = async (
  ShowApplication_ID,
  ApplicationStatus
) => {
  const data = await axios.put(
    `${BASE_URL}/Application/SetStatus`,
    {},
    {
      params: {
        sessionId: sessionId ? sessionId : null,
        ShowApplication_ID: ShowApplication_ID,
        ApplicationStatus: ApplicationStatus,
      },
    }
  );
  return data;
};

export const SetDarkMode = async (darkMode) => {
  const data = await axios.post(
    `${BASE_URL}/user/SetDarkMode`,
    {},
    {
      params: { darkMode: darkMode },
    }
  );
  return data;
};

export const UpdateDefaultCustomerData = async (defaultCustomer_Id) => {
  console.log(sessionId);
  const { data } = await axios.post(
    `${BASE_URL}/User/SetDefaultCustomer`,
    {},
    {
      params: {
        sessionId,
        defaultCustomer_Id,
      },
    }
  );
  return data;
};

export const register = async (
  userName,
  password,
  firstName,
  lastName,
  email,
  mobileNumber,
  defaultLanguage
) => {
  const data = await axios.post(
    `${BASE_URL}/User/Insert`,

    {
      username: userName,
      password: password,
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobileNumber: mobileNumber,
      defaultLanguage: defaultLanguage,
    }
  );
  return data;
};

export const RequestConfirmEmail = async () => {
  const data = await axios.post(`${BASE_URL}/user/RequestConfirmEmail`, {}, {});
  return data;
};

export const ConfirmEmail = async (UserId, Code) => {
  const data = await axios.post(
    `${BASE_URL}/user/ConfirmEmail`,
    {},
    {
      params: {
        UserId,
        Code,
      },
    }
  );
  return data;
};

export const ConfirmJoinCustomer = async (JoinId) => {
  const data = await axios.post(
    `${BASE_URL}/user/CustomerJoinConfirm`,
    {},
    {
      params: {
        JoinId,
      },
    }
  );
  return data;
};

export const UpdateDefaultUserLanguage = async (Language) => {
  console.log(Language);
  const { data } = await axios.post(
    `${BASE_URL}/user/SetDefaultLanguage`,
    {},
    {
      params: {
        Language,
      },
    }
  );
  return data;
};

export const GetShowApplication = async (showApplication_Id) => {
  const { data } = await axios.get(`${BASE_URL}/Application/Get`, {
    params: {
      sessionId,
      showApplication_Id,
    },
  });
  return data;
};

export const GetNewShowApplication = async (Show_ID, query = "") => {
  console.log(Show_ID)
  try {
    const { data } = await axios.get(`${BASE_URL}/Application/GetNew${query}`, {
      params: {
        sessionId,
        Show_ID,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const GetApplicationResume = async (ShowApplication_ID) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/Application/GetResume`, {
      params: {
        sessionId,
        ShowApplication_ID,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const SetStatus = async (ShowApplication_ID, ApplicationStatus) => {
  try {
    const { data } = await axios.put(
      `${BASE_URL}/Application/SetStatus?`,
      {},
      {
        params: {
          sessionId,
          ShowApplication_ID,
          ApplicationStatus,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const GetAllContacts = async () => {
  const { data } = await axios.get(`${BASE_URL}/Contact/GetAll`, {
    params: {
      sessionId,
    },
  });
  return data;
};

export const DeleteContact = async (contact_Id, ReasonForDelete) => {
  const { data } = await axios.delete(
    `${BASE_URL}/Contact/Delete`,

    {
      params: {
        sessionId,
        contact_Id,
        ReasonForDelete,
      },
    },
    {}
  );
  return data;
};

export const GetAllContactFunctions = async () => {
  const { data } = await axios.get(`${BASE_URL}/ContactFunction/GetAll`, {
    params: {
      sessionId,
    },
  });
  return data;
};

export const GetShowList = async (showType) => {
  const { data } = await axios.get(`${BASE_URL}/Show/GetAll`, {
    params: {
      sessionId,
      showType,
    },
  });
  return data;
};

export const GetApplicationList = async (show_ID, statuses, showType) => {
  const { data } = await axios.get(`${BASE_URL}/Application/GetList`, {
    params: {
      sessionId,
      show_ID,
      statuses,
      showType,
    },
  });
  return data;
};

export const SaveContact = async (props) => {
  console.log(props)
  const { data } = await axios.put(
    `${BASE_URL}/Contact/Save`,
    {
      contact_ID: props.contact_ID,
      userName: props.username,
      title: props.title,
      academic: props.academic,
      lastName: props.lastName,
      firstName: props.firstName,
      customer_ID: props.customer_ID || defaultCustomer,
      contactFunction_ID: props.contactFunction_ID,
      mobileNumber: props.mobileNumber,
      phoneNumber: props.phoneNumber,
      email: props.email,
      note: props.note,
    },
    {
      params: { sessionId: sessionId ? sessionId : null },
    }
  );
  return data;
};
export const GetFiles = async () => {
  const { data } = await axios.get(`${BASE_URL}/FileStorage/GetList`, {
    params: {
      sessionId,
    },
  });
  return data;
};
export const GetUploadTypes = async () => {
  const { data } = await axios.get(`${BASE_URL}/FileStorage/GetUploadTypes`, {
    params: {
      sessionId,
    },
  });
  return data;
};
export const UploadFiles = async ({ file, fileType, fileDescription }) => {
  const formData = new FormData();
  formData.append("file", file);

  const { data } = await axios.post(
    `${BASE_URL}/FileStorage/Upload`,
    formData,
    {
      params: { fileType, fileDescription },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};
export const DeteleFiles = async ({ fileId }) => {
  const { data } = await axios.delete(`${BASE_URL}/FileStorage/Delete`, {
    params: {
      fileId,
    },
  });
  return data;
};
//

export const UpdateFiles = async ({ fileId, fileDescription }) => {
  const { data } = await axios.post(
    `${BASE_URL}/FileStorage/Update`,
    {},
    {
      params: {
        fileId,
        fileDescription,
      },
    }
  );
  return data;
};
export const GetDocumentsTypes = async () => {
  const { data } = await axios.get(`${BASE_URL}/Document/GetTypes`, {});
  return data;
};

export const GetDocumentsList = async () => {
  const { data } = await axios.get(`${BASE_URL}/Document/GetList`, {
    params: {
      typeKey: "1",
      dateFrom: "1.1.2023",
      dateTo: "1.1.2023",
      showKind_ID: "00000000-0000-0000-0000-000000000000",
      searchString: "",
    },
  });
  return data;
};

export const GetDashboardMessages = async () => {
  const data = await axios.get(`${BASE_URL}/Message/GetDashboardMessages`, {});
  return data;
};
export const GetMessagesTabs = async () => {
  const data = await axios.get(`${BASE_URL}/Message/GetTabs`, {});
  return data;
};

export const GetMessagesThreads = async ({
  type,
  unreadOnly,
  pageNo,
  pageSize,
}) => {
  const data = await axios.get(`${BASE_URL}/Message/GetThreads`, {
    params: {
      type,
      unreadOnly,
      pageNo,
      pageSize,
    },
  });
  return data;
};
export const GetMessages = async ({ threadId }) => {
  const data = await axios.get(`${BASE_URL}/Message/GetMessages`, {
    params: {
      threadId,
    },
  });
  return data;
};

export const AddMessage = async ({
  messageId,
  threadId,
  body,
  isUnread,
  isUnreadByAdmin,
  code,
  message,
}) => {
  const values = await axios.post(
    `${BASE_URL}/Message/AddMessage`,
    { messageId, threadId, body, isUnread, isUnreadByAdmin, code, message },
    {
      params: {},
    }
  );
};
export const NewThread = async ({ subject, body, type, code, message }) => {
  const values = await axios.post(
    `${BASE_URL}/Message/NewThread`,
    { subject, body, type, code, message },
    {
      params: {},
    }
  );
  return values;
};

export const DeleteThread = async ({ threadId }) => {
  const values = await axios.post(
    `${BASE_URL}/Message/DeleteThread`,
    {},
    {
      params: { threadId },
    }
  );
  return values;
};

export const CloseThread = async ({ threadId }) => {
  const values = await axios.post(
    `${BASE_URL}/Message/CloseThread`,
    {},
    {
      params: { threadId },
    }
  );
  return values;
};
