import { combineReducers } from "redux";

import authReducer from "./auth";
import fairReducer from "./fair";
import alertReducer from "./alert";

export default combineReducers({
  fairReducer,
  authReducer,
  alertReducer,
});
