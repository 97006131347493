import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  FormHelperText,
  Switch,
  CircularProgress,
} from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import AppTextField from "components/input-fields/AppTextField";
import { H1, H5, Paragraph, Small } from "components/Typography";
import { useFormik } from "formik";
import useAuth from "hooks/useAuth";
import { TextFieldWrapper } from "page-sections/authentication/StyledComponents";
import { useState, useEffect, Fragment } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import lightLogo from "../../assets/logo/logo-light.png";
import darkLogo from "../../assets/logo/logo-dark.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Navigate } from "react-router";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { store } from "../../store";
import { ClearAlert, UpdateAlert } from "../../store/actions/alert";
import { UpdateCustomerVerified, UpdateEmailVerified } from "../../store/actions/auth";
import { ConfirmEmail, ConfirmJoinCustomer } from "../../contexts/API";

const CustomerJoinConfirm = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  let navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const isLight = theme.palette.mode === "light";

  useEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const JoinId = urlParams.get("JoinId");
     

    const response = await ConfirmJoinCustomer(JoinId);
    setData(response?.data);
    setError(response?.data?.message || "NoError");
    if (response?.data?.code > 0) {
      store.dispatch(UpdateCustomerVerified(true));
    }
    setLoading(false);
  }, []);

  return (
    <FlexRowAlign
      flexDirection="column"
      sx={{
        height: {
          sm: "100%",
        },
      }}
    >
      <Card
        sx={{
          padding: 4,
          minWidth: "50%",
          boxShadow: 1,
        }}
      >
        <FlexRowAlign flexDirection="column" mb={2}>
          <Box width={200} mb={1}>
            {isLight ? (
              <img src={lightLogo} width="100%" height="100%" alt="Uko Logo" />
            ) : (
              <img src={darkLogo} width="100%" height="100%" alt="Uko Logo" />
            )}
          </Box>
          <H1 fontSize={24} fontWeight={700}>
            {t("Customer Join Confirm")}
          </H1>
        </FlexRowAlign>

        <Divider
          sx={{
            my: 3,
            width: "100%",
            alignItems: "center",
          }}
        ></Divider>
        {loading && !error ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <H5 sx={{ textAlign: "center" }}>{error}</H5>
            <Box mt={3} sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                disabled={data?.code > 0 ? false : true}
                mt={2}
                sx={{ textAlgin: "center" }}
                variant="contained"
                onClick={() => {
                  if (data?.code > 0) {
                    store.dispatch(UpdateCustomerVerified(true));
                    navigate("/main");
                  }
                }}
              >
                {t("Join Continue")}
              </Button>
            </Box>
          </>
        )}
      </Card>
    </FlexRowAlign>
  );
};

export default CustomerJoinConfirm;
