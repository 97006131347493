export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_DEFAULT_CUSTOMER = "UPDATE_DEFAULT_CUSTOMER";
export const UPDATE_DEFAULT_LANGUAGE = "UPDATE_DEFAULT_LANGUAGE";
export const UPDATE_EMAIL_VERIFIED = "UPDATE_EMAIL_VERIFIED";
export const UPDATE_CUSTOMER_VERIFIED = "UPDATE_EMAIL_VERIFIED";
export const UPDATE_USER_TOKEN = "UPDATE_USER_TOKEN";

export const Login = (user) => {
  return {
    type: LOGIN,
    user: user,
  };
};
export const LoginError = (error) => {
  return {
    type: 'LOGIN_ERROR',
    error: error,
  };
};
export const Logout = () => {
  return {
    type: LOGOUT,
  };
};

export const UpdateDefaultCustomer = (
  defaultCustomerID,
  defaultCustomerName
) => {
  return {
    type: UPDATE_DEFAULT_CUSTOMER,
    defaultCustomerID,
    defaultCustomerName,
  };
};

export const UpdateDefaultLanguage = (defaultLanguage) => {
  return {
    type: UPDATE_DEFAULT_LANGUAGE,

    defaultLanguage,
  };
};

export const UpdateEmailVerified = (verified) => {
  return {
    type: UPDATE_EMAIL_VERIFIED,
    verified,
  };
};

export const UpdateCustomerVerified = (verified) => {
  return {
    type: UPDATE_CUSTOMER_VERIFIED,
    verified,
  };
};

export const updateToken = (token) => {
  return {
    type: UPDATE_USER_TOKEN,
    token,
  };
};
