import { ALERT, CLEAR_ALERT } from "../actions/alert";

export default (prevState = { alert: "" }, action) => {
  switch (action.type) {
    case ALERT:
      return { ...prevState, alert: action.alert };
    case "LOGIN_ERROR":
      return { ...prevState, error: action.error };
    case CLEAR_ALERT:
      return { ...prevState, alert: "" };

    default:
      return prevState;
  }
};
