import LoadingScreen from "components/LoadingScreen";
import DashboardLayoutV3 from "layouts/layout-v3/DashboardLayout";
import CustomerJoinConfirm from "pages/authentication/customer-join-confirm";
import FilesList from "pages/Files/FilesList";
import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
}; // dashboards

const LoginV2 = Loadable(lazy(() => import("./pages/authentication/login-v2")));
const Register = Loadable(
  lazy(() => import("./pages/authentication/register"))
);
const RegisterV2 = Loadable(
  lazy(() => import("./pages/authentication/register-v2"))
);
const NewPassword = Loadable(
  lazy(() => import("./pages/authentication/new-password"))
);

const ConfirmAccount = Loadable(
  lazy(() => import("./pages/authentication/account-confirm"))
);
const ForgetPassword = Loadable(
  lazy(() => import("./pages/authentication/forget-password"))
);
const ForgetPasswordV2 = Loadable(
  lazy(() => import("./pages/authentication/forget-password-v2"))
);
const TwoStepVerify = Loadable(
  lazy(() => import("./pages/authentication/two-step-verification"))
); // admin ecommerce
// Customer Navigations ========================

const CreateCustomer = Loadable(
  lazy(() => import("./pages/Customer/CreateCustomer"))
);

const SelectOrRegisterCustomer = Loadable(
  lazy(() => import("./pages/Customer/selectOrRegisterCustomer"))
);

const FindCustomer = Loadable(
  lazy(() => import("./pages/Customer/FindCustomer"))
);

const ReviewCustomerData = Loadable(
  lazy(() => import("./pages/Customer/reviewCustomerData"))
);

const SelectedUserCustomers = Loadable(
  lazy(() => import("./pages/User/selectedUserCustomers"))
);

const NotSelectableCustomer = Loadable(
  lazy(() => import("./pages/Customer/NotSelectableCustomer"))
);

const DefaultCustomerInfo = Loadable(
  lazy(() => import("./pages/Customer/DefaultCustomerInfo"))
);

const Main = Loadable(lazy(() => import("./pages/Main/main")));

const FairView = Loadable(lazy(() => import("./pages/Fairs/FairView")));

const AssassmentView = Loadable(
  lazy(() => import("./pages/Assassments/AssassmentView"))
);

const UserSettings = Loadable(lazy(() => import("./pages/User/userSettings")));

const Error = Loadable(lazy(() => import("./pages/404")));

const FairList = Loadable(lazy(() => import("./pages/Lists/FairList")));

const FairArchive = Loadable(lazy(() => import("./pages/Lists/FairArchive")));
const AssassmentsList = Loadable(
  lazy(() => import("./pages/Lists/AssassmentList"))
);
const AssassmentsArchive = Loadable(
  lazy(() => import("./pages/Lists/AssassmentArchive"))
);

const Applications = Loadable(
  lazy(() => import("./pages/Applications/Applications"))
);

const Contacts = Loadable(lazy(() => import("./pages/Contacts/Contact")));

const WorkInProgress = Loadable(lazy(() => import("./pages/WorkInProgress")));

const MessagesThreads = Loadable(
  lazy(() => import("./pages/Messages/MessagesThreads"))
);

const Message = Loadable(lazy(() => import("./pages/Message/Message")));

const Documents = Loadable(lazy(() => import("./pages/Documents/Documents")));

const VerifyEmail = Loadable(
  lazy(() => import("./pages/authentication/verify-email"))
);

const routes = () => {
  return [
    ...authRoutes,
    {
      path: "",
      element: <DashboardLayoutV3 />,
      children: CustomerRoutes,
    },
    {
      path: "*",
      element: <Error />,
    },

    ...CustomerRoutes,
  ];
};

const CustomerRoutes = [
  {
    path: "work-in-progress",
    element: <WorkInProgress />,
  },
  {
    path: "create-customer",
    element: <CreateCustomer />,
  },
  {
    path: "main",
    element: <Main />,
  },
  {
    path: "select-or-register-customer",
    element: <SelectOrRegisterCustomer />,
  },
  {
    path: "customer-info",
    element: <DefaultCustomerInfo />,
  },
  {
    path: "find-customer",
    element: <FindCustomer />,
  },
  {
    path: "review-customer-data",
    element: <ReviewCustomerData />,
  },
  {
    path: "selected-user-customers",
    element: <SelectedUserCustomers />,
  },
  {
    path: "not-selectable-customer",
    element: <NotSelectableCustomer />,
  },
  {
    path: "user/settings",
    element: <UserSettings />,
  },
  {
    path: "fairs",
    element: <FairView />,
  },
  {
    path: "assassments",
    element: <AssassmentView />,
  },
  {
    path: "fair-list",
    element: <FairList />,
  },
  {
    path: 'fair-archive',
    element: <FairArchive />
  },
  {
    path: "assassment-list",
    element: <AssassmentsList />,
  },
  {
    path: 'assassment-archive',
    element: <AssassmentsArchive />,
  },
  {
    path: "contact",
    element: <Contacts />,
  },
  {
    path: "applications",
    element: <Applications />,
  },
  {
    path: "account-confirm",
    element: <ConfirmAccount />,
  },
  {
    path:'customer-join-confirm',
    element: <CustomerJoinConfirm />
  },
  {
    path: "messages-threads",
    element: <MessagesThreads />,
  },
  {
    path: "message",
    element: <Message />,
  },
  {
    path: "documents",
    element: <Documents />,
  },
  {
    path: "files",
    element: <FilesList />,
  },
];

const authRoutes = [
  {
    path: "/",
    element: <Navigate to="/login" />,
  },
  {
    path: "login",
    element: <LoginV2 />,
  },
  {
    path: "register",
    element: <RegisterV2 />,
  },

  {
    path: "change-password",
    element: <NewPassword />,
  },
  {
    path: "forget-password",
    element: <ForgetPasswordV2 />,
  },

  {
    path: "two-step-verification",
    element: <TwoStepVerify />,
  },
  {
    path: "verify-email",
    element: <VerifyEmail />,
  },
];

export default routes;
