import {
  AppBar,
  Box,
  ClickAwayListener,
  IconButton,
  styled,
  Toolbar,
  useMediaQuery,
  Card,
} from "@mui/material";
import { SettingsContext } from "contexts/settingsContext";
import MenuLeft from "icons/MenuLeft";
import MenuLeftRight from "icons/MenuLeftRight";
import SearchIcon from "icons/SearchIcon";
import ThemeIcon from "icons/ThemeIcon";
import { Fragment, Suspense, useContext, useEffect, useState } from "react";
import LanguagePopover from "./popovers/LanguagePopover";
import NotificationsPopover from "./popovers/NotificationsPopover";
import ProfilePopover from "./popovers/ProfilePopover";
import ServicePopover from "./popovers/ServicePopover";
import SearchBar from "./SearchBar"; // ------------------------------------------------
import { Span } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import { useSelector } from "react-redux";
import { SetDarkMode } from "../../contexts/API";

// ------------------------------------------------
// custom styled components
const DashboardHeaderRoot = styled(AppBar)(({ theme }) => ({
  zIndex: 11,
  boxShadow: "none",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  backdropFilter: "blur(6px)",
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
}));
const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));
const ToggleIcon = styled(Box)(({ theme, width }) => ({
  height: 3,
  margin: "5px",
  marginLeft: 0,
  width: width || 25,
  borderRadius: "10px",
  transition: "width 0.3s",
  backgroundColor: theme.palette.primary.main,
}));

const DashboardHeader = (props) => {
  const { setShowMobileSideBar } = props;
  const [openSearchBar, setSearchBar] = useState(false);
  const { settings, saveSettings } = useContext(SettingsContext);
  const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const downMd = useMediaQuery((theme) => theme.breakpoints.down(1200));

  const user =
    useSelector((state) => state.persistedReducer.authReducer.user) || "";

  useEffect(() => {
    saveSettings({
      ...settings,
      theme: localStorage.getItem("settings")
        ? JSON.parse(localStorage.getItem("settings")).theme
        : user.isDarkMode
        ? "dark"
        : "light",
    });
  }, [user]);

  const handleChangeDirection = (value) => {
    saveSettings({ ...settings, direction: value });
  };

  const handleChangeTheme = async (value) => {
    saveSettings({ ...settings, theme: value });
    let modeType = value == "dark" ? true : false;
    await SetDarkMode(modeType);
  };

  return (
    <DashboardHeaderRoot position="sticky">
      <StyledToolBar>
        {downMd && (
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={setShowMobileSideBar}
          >
            <ToggleIcon />
            <ToggleIcon width={18} />
            <ToggleIcon width={9} />
          </Box>
        )}
        <FlexBox sx={{ width: "50%", p: 1, py: 2 }}>
          <Span
            color="text.secondary"
            sx={{
              fontWeight: 700,
              display: "inline-block",
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {" "}
            {user.defaultCustomerName}
          </Span>
        </FlexBox>
        <Box flexGrow={1} ml={1} />

        {settings.theme === "light" ? (
          <StyledIconButton onClick={() => handleChangeTheme("dark")}>
            <ThemeIcon />
          </StyledIconButton>
        ) : (
          <StyledIconButton onClick={() => handleChangeTheme("light")}>
            <ThemeIcon />
          </StyledIconButton>
        )}

        <Fragment>
          <Suspense fallback="loading">
            <LanguagePopover />
          </Suspense>
        </Fragment>

        <ProfilePopover />
      </StyledToolBar>
    </DashboardHeaderRoot>
  );
};

export default DashboardHeader;
