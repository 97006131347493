import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import { store } from "./store";

let initialized = false;

const initI18n = async () => {
  if (initialized) {
    return; // Exit the function if already initialized
  }

  const defaultLanguageFromUser =
    store.getState().persistedReducer?.authReducer?.user?.defaultLanguage;

  const loggedIn = store.getState().persistedReducer.authReducer.authenticated;

  await i18next
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      react: {
        useSuspense: false,
      },
      lng:
        defaultLanguageFromUser || loggedIn
          ? (window.navigator.language === "sl" && "sl") ||
            (window.navigator.language === "de" && "de")
          : localStorage.getItem("defaultLanguage") || "en",
      fallbackLng: "en",
      returnNull: false,
      missingKeyHandler: (lngs, ns, key, fallbackValue) => key,
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `https://portal-be.pomurski-sejem.si/Common/GetTranslations?language={{lng}}`,
      },
      debug: false,
      ns: ["labels", "messages"],
    });

  initialized = true; // Update the flag to indicate initialization

  // Rest of your code
};

// Call initI18n at an appropriate location in your code

initI18n();