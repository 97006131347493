import { KeyboardArrowDown, UploadFile } from "@mui/icons-material";
import { Button, IconButton, Modal, styled, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import SearchInput from "components/input-fields/SearchInput";
import { H2, H3, H4, H5, Paragraph } from "components/Typography";
import { lightTheme } from "constants";
import { UpdateFiles, UploadFiles } from "contexts/API";
import { t } from "i18next";
import Delete from "icons/Delete";
import { useRef } from "react";
import { useEffect, useState } from "react";
import ImageUpload from "page-sections/admin-ecommerce/add-product/ImageUpload";
import { useCallback } from "react";

const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
  backgroundColor: lightTheme(theme)
    ? theme.palette.action.hover
    : theme.palette.divider,
}));

export default function FileModal(props) {
  const upload = useRef();
  const theme = useTheme();
  const [types, setTypesSelect] = useState(null);
  const [selectedItem, setSelectedItem] = useState({ description: "" });
  const [fileItem, setFileItem] = useState(null);
  const [update, setupdate] = useState(null);
  const [files, setFiles] = useState([]);

  const handleChangeDescription = (value) => {
    console.log(value);
  };

  const handleDropFile = useCallback((acceptedFiles) => {
    const files = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    if (!files) return;
    setFiles(files);
    setFileItem(files[0]);
    setSelectedItem((pre) => ({
      ...pre,
      description:
        files[0]?.name?.replace(/^(.+)(\..+)$/, "$1"),
    }));
  }, []);

  const handleRemoveImage = (file) => {
    const filteredFiles = files.filter((_file) => _file !== file);
    setFiles(filteredFiles);
    setFileItem(null);
    setSelectedItem((pre) => ({ ...pre, description: "" }));
  };
  useEffect(() => {
    const findItem = types?.find((item) => item.selected);

    setSelectedItem({
      description: props.edit
        ? props.updateFilevalues?.fileDescription
        : fileItem?.name || "",
      extension: selectedItem?.extension || findItem?.FileExtension,
      value: findItem?.FileType,
    });
    setupdate(null);
    return () => {
      if (props.open) {
        setFileItem(null);
        setSelectedItem((pre) => ({ ...pre, description: "" }));
      }
    };
  }, [props.open]);

  useEffect(() => {
    setupdate(props.updateFilevalues);
  }, [props.updateFilevalues]);

  useEffect(() => {
    props.uploadtypes &&
      setTypesSelect(
        props.uploadtypes.map((item, index) => ({
          selected: index === 0,
          id: index,
          ...item,
        }))
      );
  }, [props.uploadtypes]);

  useEffect(() => {
    const findItem = types?.find((item) => item.selected);

    if (!findItem) return;

    setSelectedItem((pre) => ({
      ...pre,
      value: findItem.FileType,
      text: findItem.FileCaption,
      extension: findItem.FileExtension,
      instructions: findItem.Instructions,
    }));
  }, [types]);
  //

  const handleUpload = (event) => {
    if (!upload.current?.files) return;
    setFileItem(upload.current.files[0]);
  };

  const closeModal = () => {
    props.closeModal();
  };
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 1,
          bgcolor: "background.paper",
          width: "50%",
          minWidth: 350,
          boxShadow: 24,
          p: 4,
        }}
      >
        <H3>{update ? t("Edit file") : t("Upload file")} </H3>

        {!update && (
          <AppTextField
            sx={{ margin: "20px 0" }}
            select
            name="types"
            type="text"
            fullWidth
            onChange={(e) =>
              setTypesSelect((pre) =>
                pre.map((item) =>
                  item.FileType === e.target.value
                    ? { ...item, selected: true }
                    : { ...item, selected: false }
                )
              )
            }
            SelectProps={{
              native: true,
              IconComponent: KeyboardArrowDown,
            }}
            label={t("Please select file type from the list")}
            InputLabelProps={{ shrink: true }}
            value={selectedItem?.value}
            defaultValue={""}
          >
            {/* <option value="" disabled selected hidden>
              {t("Select")}
            </option> */}
            {types?.map((item) => (
              <option key={item.id} value={item.FileType}>
                {item.FileCaption}
              </option>
            ))}
          </AppTextField>
        )}
        {!update && (
          <Box my={1}>
            {selectedItem && (
              <H5>
                {t("Available extensions")}: {selectedItem.extension}
              </H5>
            )}
          </Box>
        )}

        {!update && (
          <FlexBox
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
          >
            <ImageUpload
              extensions={selectedItem.extension
                ?.replace(/\*/g, "")
                .replace(/\;/g, ",")}
              handleRemoveImage={handleRemoveImage}
              onDrop={handleDropFile}
              files={[fileItem] || []}
              preview={files[0]?.preview}
              uploading={!!selectedItem.extension}
              multiple={false}
              customStyle={{ boxShadow: "none" }}
              fileName={fileItem?.name}
            />
            {/* <H5>{t("Upload file")}</H5> */}
            {/* <IconButton
              color="primary"
              aria-label="upload filr"
              component="label"
              disabled={!selectedItem || !selectedItem.extension}
             >
              <input
               // accept={selectedItem.extension}
                 ref={upload}
                hidden
                type="file"
                onChange={handleUpload}  
              />

              <UploadFile />
            </IconButton>  */}
          </FlexBox>
        )}
        <Box my={2}>
          <AppTextField
            value={selectedItem.description}
            fullWidth
            name="firstName"
            variant="outlined"
            onChange={(event) => {
              const description = event.currentTarget?.value;
              setSelectedItem((pre) => ({
                ...pre,
                description,
              }));
            }}
            label={t("File description")}
          />
        </Box>
        {fileItem && !update && (
          <Box mb={2}>
            {/* <FlexBox
              justifyContent="flex-start"
              flexDirection="row"
              alignItems="center"
              mb={1}
            >
              <Paragraph
                color={
                  theme.palette.primary[
                    selectedItem?.extension?.includes(
                      fileItem.name.replace(/.+(\.[^.]+)/, "$1")
                    )
                      ? "main"
                      : "red"
                  ]
                }
              >
                {fileItem.name}
              </Paragraph>
              <IconButton
                disabled={false}
                onClick={(event) => {
                  setFileItem(null);
                }}
              >
                <Delete
                  sx={{
                    color:
                      theme.palette.primary[
                        selectedItem?.extension?.includes(
                          fileItem.name.replace(/.+(\.[^.]+)/, "$1")
                        )
                          ? "main"
                          : "red"
                      ],
                    fontSize: 12,
                  }}
                />
              </IconButton>
            </FlexBox> */}

            {!selectedItem?.extension
              ?.toLowerCase()
              ?.includes(
                fileItem.name?.toLowerCase()?.replace(/.+(\.[^.]+)/, "$1")
              ) && (
              <H5 fontSize={10} color={theme.palette.primary.red}>
                Extensions: ({selectedItem?.extension}) are not valid
              </H5>
            )}
          </Box>
        )}
        {selectedItem && (
          <Box mb={2}>
            <Paragraph>
              {selectedItem?.instructions?.replace(/<[^>]*>/g, "")}
            </Paragraph>
          </Box>
        )}

        <Button
          disabled={
            !update
              ? !(
                  fileItem &&
                  selectedItem &&
                  selectedItem?.extension?.toLowerCase()?.includes(
                    fileItem.name?.toLowerCase()?.replace(/.+(\.[^.]+)/, "$1")
                  ) &&
                  selectedItem?.description?.replace(/\s/g, "")
                )
              : !selectedItem.description
          }
          fullWidth
          size="small"
          onClick={() => {
            (update &&
              UpdateFiles({
                fileId: update.fileId,
                fileDescription: selectedItem.description,
              }).then((res) => res.code === 1 && closeModal())) ||
              UploadFiles({
                file: fileItem,
                fileType: selectedItem.value,
                fileDescription: selectedItem.description,
              }).then((res) => res.code === 1 && closeModal());
          }}
          variant="contained"
        >
          {t("Save")}
        </Button>
      </Box>
    </Modal>
  );
}
